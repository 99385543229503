import { useState, useEffect } from 'react';
import Clock from 'react-live-clock';
import './App.css';

const week = new Array("Neděle", "Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota");
let nowDate = new Date();
const pidUrl = 'https://pid.trueslav.dev/';

const getToday = (shift = 0) => {
  return week[(nowDate.getDay() + shift) % 7];
};

const getFullDate = (shift = 0) => {
  const date = new Date();
  date.setDate(date.getDate() + shift)
  return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
};

function App() {

  // https://pid.trueslav.dev/2way?from=Namesti%20republiky
  // https://sprava-zeleznic.trueslav.dev/?key=3444

  const [trams, setTrams] = useState({});
  const [trains, setTrains] = useState([]);
  const [weather, setWeather] = useState();
  const [emptyTrams, setEmptyTrams] = useState({ revolucni: 0, florenc: 0 });  
  const [fullDateToday, setFullDateToday] = useState(getFullDate());
  const [fullDateTomorrow, setFullDateTomorrow] = useState(getFullDate(1));
  const [fullDateDayAfterTomorrow, setFullDateDayAfterTomorrow] = useState(getFullDate(2));

  const [today, setToday] = useState(getToday());
  const [tomorrow, setTomorrow] = useState(getToday(1));
  const [dayAfterTomorrow, setDayAfterTomorrow] = useState(getToday(2));


  const loadWeatherData = () => {
    setTimeout(() => {
      loadWeatherData();
    }, 1000 * 60 * 60 * 12);

    const fetchWeatherData = async () => {
      const responseWeather = await fetch('https://api.screenmanager.tech/graphql', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: `query CurrentWeather {
      CurrentWeather(query: "Prague, Czech Republic") {
        location {
          name
        }
        current {
          temperature
          webIcon
          text
        }
        forecast {
          temperatureMax
          temperatureAvg
          temperatureMin
          webIcon
          text
          date
          dateTimestamp
          chanceOfRain
          humidityAvg
          hourForecast {
            time
            temperature
            text
            webIcon
          }
        }
      }
    }`,
          operationName: 'CurrentWeather'
        }),
      });
      const jsonWeather = await responseWeather.json();
      setWeather(jsonWeather.data);
    }
    fetchWeatherData();
  };

  const loadData = () => {
    setTimeout(() => {
      setFullDateToday(getFullDate());
      setFullDateTomorrow(getFullDate(1));
      setFullDateDayAfterTomorrow(getFullDate(2));
      setToday(getToday());
      setTomorrow(getToday(1));
      setDayAfterTomorrow(getToday(2));
      loadData();
      nowDate = new Date();
    }, 60000);
    const fetchTramData = async () => {
      const responsePID = await fetch(pidUrl);
      const jsonPID = await responsePID.json();
      const { revolucni, florenc } = jsonPID;
      const emptyRevolucni = [];
      for(let i = 0; i < 7 - revolucni.length;i++) {
        emptyRevolucni.push(i);
      }
      const emptyFlorenc = [];
      for(let i = 0; i < 7 - florenc.length;i++) {
        emptyFlorenc.push(i);
      }
      if(revolucni?.length > 0 && florenc?.length > 0) {
        setEmptyTrams({ revolucni: emptyRevolucni, florenc: emptyFlorenc });
        setTrams({ revolucni, florenc });
      }
    }
    const fetchTrainData = async () => {
      const responseSpravaZeleznic = await fetch('https://sprava-zeleznic.trueslav.dev/?key=3444');
      const jsonSpravaZeleznic = await responseSpravaZeleznic.json();
      const trainsData = jsonSpravaZeleznic.slice(0, 7);
      setTrains(trainsData);
    }
    fetchTramData();
    fetchTrainData();
  }

  useEffect(() => {
    loadData();
    loadWeatherData();
  }, []);

  return (
    <main>
      <section className="tram">
        <table className="table">
          <tr className="table__header">
            <th rowSpan="2">
              <img
                className="vehicleImg"
                src="Ikonky/tramvaj.png"
                alt="tram" />
            </th>
            <th
              className="table__header table__header--bold"
              colspan="3">
              TRAMVAJ
            </th>
          </tr>
          <tr className="table__header table__header--bigger">
            <th colSpan="4">TRAM</th>
          </tr>
          <tr className="table__header">
            <th>
              <img
                className="arrowImg"
                src="Ikonky/sipka-vlevo.png"
                alt="left arrow" />
            </th>
            <th colSpan="4">REVOLUČNÍ</th>
          </tr>
          <tr className="table__header">
            <th className="table__cell--center">Linka</th>
            <th>Cíl</th>
            <th className="table__cell--center">Odjezd</th>
            <th className="table__cell--center">Zpoždení</th>
          </tr>
          <tr className="table__header table__header--bigger"><th colSpan="7"></th></tr>
          {trams && trams.revolucni && trams.revolucni.map(item => {
            return <tr className="table__row--tram">
              <td className="table__cell--center">{item.line}</td>
              <td>{item.destination}</td>
              <td className="table__cell--center">{item.departureTime}</td>
              <td className="table__cell--center">{item.delay}</td>
            </tr>
          })}
          {emptyTrams.revolucni && emptyTrams.revolucni.map(_ => {
            return <tr className="table__row--tram tram-invisible-text">
              <td className="table__cell--center">---</td>
              <td>---</td>
              <td className="table__cell--center">---</td>
              <td className="table__cell--center">---</td>
            </tr>
          })}
        </table>
        <table className="table">
          <tr className="table__header">
            <th>
              <img
                className="arrowImg"
                src="Ikonky/sipka-vpravo.png"
                alt="right arrow" />
            </th>
            <th colSpan="4">MASARYKOVO NÁDRAŽÍ | FLORENC</th>
          </tr>
          <tr className="table__header">
            <th className="table__cell--center">Linka</th>
            <th>Cíl</th>
            <th className="table__cell--center">Odjezd</th>
            <th className="table__cell--center">Zpoždení</th>
          </tr>
          <tr className="table__header table__header--bigger"><th colSpan="7"></th></tr>
          {trams && trams.florenc && trams.florenc.map(item => {
            return <tr className="table__row--tram">
              <td className="table__cell--center">{item.line}</td>
              <td>{item.destination}</td>
              <td className="table__cell--center">{item.departureTime}</td>
              <td className="table__cell--center">{item.delay}</td>
            </tr>
          })}
          {emptyTrams.florenc && emptyTrams.florenc.map(_ => {
            return <tr className="table__row--tram tram-invisible-text">
              <td className="table__cell--center">---</td>
              <td>---</td>
              <td className="table__cell--center">---</td>
              <td className="table__cell--center">---</td>
            </tr>
          })}
        </table>
      </section>
      <section className="train">
        <table className="table">
          <tr className="table__header">
            <th rowSpan="2">
              <img
                className="vehicleImg"
                src="Ikonky/vlak.png"
                alt="tram" />
            </th>
            <th
              className="table__header table__header--bold"
              colspan="6">
              VLAKY
            </th>
          </tr>
          <tr className="table__header table__header--bigger">
            <th colSpan="6">TRAIN</th>
          </tr>
          <tr className="table__header"><th colSpan="7"></th></tr>
          <tr className="table__header"><th colSpan="7"></th></tr>
          <tr className="table__header"><th colSpan="7"></th></tr>
          <tr className="table__header">
            <th className="table__cell--center last--header">Pravidelný</th>
            <th className="table__cell--center last--header">Aktuální</th>
            <th className="table__cell--center last--header">Vlak</th>
            <th className="table__cell--center last--header">Dopravce</th>
            <th className="table__cell--center last--header">Linka</th>
            <th className="last--header">Cíl</th>
            <th className="table__cell--center last--header">Nástupiště</th>
          </tr>
          {trains && trains.map(item => {
            return <tr className="table__row--train">
              <td className="table__cell--center">{item.plannedDeparture}</td>
              <td className="table__cell--center">{item.realDeparture ? item.realDeparture : item.plannedDeparture}</td>
              <td className="table__cell--center">
                <div>{item.type?.toUpperCase()}</div>
                <div>{item.number}</div>
              </td>
              <td className="table__cell--center">{item.carrier}</td>
              <td className="table__cell--center">{item.line}</td>
              <td>{item.destination}</td>
              <td className="table__cell--center">{item.station}</td>
            </tr>
          })}
        </table>
        <div className="weather">
          <div className="weather__day weather__day--1">
            <div className="weather__date--1 weather__date--today">{today}</div>
            <div className="weather__date--2">{fullDateToday}</div>
            <div className="weather__date--3"><Clock format={'HH:mm:ss'} ticking={true} timezone={'EU/Prague'} /></div>
            <div className="weather__forecast--1">{weather && weather.CurrentWeather.forecast && <img width="60px" src={weather.CurrentWeather.forecast[0]?.webIcon} />}</div>
            <div className="weather__forecast--2">{weather && weather.CurrentWeather.forecast && weather.CurrentWeather.forecast[0]?.temperatureMax}°C</div>
          </div>
          <div className="weather__day weather__day--2">
            <div className="weather__date--1 weather__date--nextDay">{tomorrow}</div>
            <div className="weather__date--2">{fullDateTomorrow}</div>
            <div className="weather__forecast--1">{weather && weather.CurrentWeather.forecast && <img width="45px" src={weather.CurrentWeather.forecast[1]?.webIcon} />}</div>
            <div className="weather__forecast--2">{weather && weather.CurrentWeather.forecast && weather.CurrentWeather.forecast[1]?.temperatureMax}°C</div>
          </div>
          <div className="weather__day weather__day--3">
            <div className="weather__date--1 weather__date--nextDay">{dayAfterTomorrow}</div>
            <div className="weather__date--2">{fullDateDayAfterTomorrow}</div>
            <div className="weather__forecast--1">{weather && weather.CurrentWeather.forecast && <img width="45px" src={weather.CurrentWeather.forecast[2]?.webIcon} />}</div>
            <div className="weather__forecast--2">{weather && weather.CurrentWeather.forecast && weather.CurrentWeather.forecast[2]?.temperatureMax}°C</div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default App;
